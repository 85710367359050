
















import { Component, Mixins, Prop } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { IRecord, UploadFileChunk } from '@/store/types'
import SystemModule from '@/store/modules/system'

@Component
export default class Recorder extends Mixins(NotifyMixin) {
  @Prop({ default: 0 })
  private time!: number

  @Prop({ default: 50000 })
  private chunkSize!: number

  private get isRecordingGlobal() {
    return SystemModule.isRecording
  }

  private get isPlayingGlobal() {
    return SystemModule.isPlaying
  }

  private isRecording = false
  private sampleRate = this.setSampleRate()

  private setSampleRate() {
    const AudioContext = window.AudioContext || (window as any).webkitAudioContext
    const { sampleRate } = new AudioContext()
    return sampleRate || 48000
  }

  private micFailed() {
    this.$emit('micFailed')
    this.notifyError('Нет доступа к микрофону. Разрешите доступ в настройках браузера, чтобы записывать сообщения.')
    SystemModule.setIsRecording(false)
    this.isRecording = false
  }

  private handleBeforeRecording() {
    this.sampleRate = this.setSampleRate()
    SystemModule.setIsRecording(true)
    this.isRecording = true
    this.$emit('beforeRecording')
  }

  private handleAfterRecording(data: IRecord) {
    SystemModule.setIsRecording(false)
    const { blob } = data
    const { type } = blob
    const file: File = new File([blob], `${Date.now()}.mp3`, { type })
    const { size, name } = file
    const uid = uuid()
    const chunks = Math.ceil(size / this.chunkSize)
    let chunk = 0

    this.uploadRecord({
      chunk,
      chunks,
      file,
      filename: name,
      size,
      uuid: uid,
    })
    this.isRecording = false
    this.$emit('afterRecording')
  }

  private uploadRecord(payload: UploadFileChunk) {
    let chunk = payload.chunk
    const start = chunk * this.chunkSize
    const end = (chunk + 1) * this.chunkSize > payload.size ? payload.size : (chunk + 1) * this.chunkSize
    let fileChunk = payload.file.slice(start, end)
    SystemModule.uploadChunk({
      ...payload,
      file: fileChunk,
    })
      .then(response => {
        if (!response.result) {
          chunk++
          this.uploadRecord({
            ...payload,
            chunk,
          })
        } else {
          this.$emit('uploadedRecording', response.result)
        }
      })
      .catch(this.notifyError)
  }
}
