













































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { DateTime } from 'luxon'

import Attachment from '@/components/_uikit/Attachment.vue'
import Player from '@/components/_uikit/Player.vue'
import AuthModule from '@/store/modules/auth'
import MessageModule from '@/store/modules/message'
import { TaskMessageResource, SelfResource, IReactionPayload } from '@/store/types'

enum MessageTypes {
  HEART = 'heart_msg',
  MISTAKES = 'work_on_mistakes',
  RATE = 'rate_msg',
}

@Component({
  components: {
    Attachment,
    Player,
  },
})
export default class ChatMessageCard extends Vue {
  @Ref() card!: HTMLDivElement

  @Prop({ required: true })
  private message!: TaskMessageResource

  @Prop({ required: false })
  private taskUuid!: string

  @Prop({ required: false })
  private masterGroupId!: number

  @Prop({ required: false })
  private masterId!: number

  @Prop({ required: false })
  private exerciseUuid!: string

  @Prop({ required: true })
  private bottomParent!: number

  @Prop({ default: false })
  private isTicket!: boolean

  // редактирование сообщений
  @Prop({ default: false })
  private editMessages!: boolean

  private isShowMenu = false
  private reactionList = ['😉', '😍', '🥳', '😟', '🥺', '😊', '👍', '❤️', '🙏', '😵', '😳']
  private innerReaction: string|null = null
  private innerReactionAuthorId: number | null = null
  private isShowReactionList = false
  private reverse = false

  private get user () {
    return AuthModule.self as SelfResource
  }

  private get isMine () {
    return +this.message.author.id === this.user.id
  }

  private get isMineReaction () {
    return this.innerReactionAuthorId === this.user.id
  }

  private get isScoreInformationMessage() {
    return this.message.type === MessageTypes.RATE
  }

  private get isScoreHeartMessage() {
    return this.message.type === MessageTypes.HEART
  }

  private get isMistakes() {
    return this.message.type === MessageTypes.MISTAKES
  }

  private get isReaction() {
    return this.message.reaction
  }

  private get files() {
    return this.message.media.filter(media => media.type !== 'audio')
  }

  private get records() {
    return this.message.media.filter(media => media.type === 'audio')
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private get isCanEdited() {
    return this.message.type === null && (DateTime.fromSQL(this.message.createdAt, { zone: 'Europe/Moscow' }).plus({ hours: Number(process.env.VUE_APP_EDIT_MESSAGES_HOURS) }) as any).ts > Date.now()
  }

  private get classes () {
    return {
      'is-mistakes': this.isMistakes,
      'is-score-heart': this.isScoreHeartMessage,
      'is-score-information': this.isScoreInformationMessage,
    }
  }

  private mounted () {
    this.$nextTick(() => {
      this.innerReaction = this.message.reaction
      this.innerReactionAuthorId = this.message.reactionAuthorId
      this.reverse = this.bottomParent - this.card.getBoundingClientRect().bottom < 60

      // (this.$refs.rList as HTMLDivElement).scrollTo({
      //   top: (this.$refs.rList as HTMLDivElement).scrollHeight,
      // })
    })
    this.$bus.$on('scrollChat', this.handleScroll)
  }

  private destroyed() {
    this.$bus.$off('scrollChat', this.handleScroll as any)
  }

  private handleOpenCloseMenu (open: boolean) {
    if (open) this.isShowMenu = true
    else {
      this.isShowMenu = false
      const elm = this.$refs.rList as HTMLDivElement

      if (elm)
        elm.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
    }
  }

  private handleClickReaction (reaction: string, index: number) {
    this.isShowMenu = false
    this.innerReaction = reaction

    const height = this.$vuetify.breakpoint.mdAndUp ? 28 : 20;

    (this.$refs.rList as HTMLDivElement).scrollTo({
      behavior: 'smooth',
      top: height * index - 1,
    })

    this.sendReaction()
  }

  private removeReaction () {
    if (this.isMineReaction) {
      this.innerReaction = null
      this.handleOpenCloseMenu(true)
      this.sendReaction()
    }
  }

  private get payload ():IReactionPayload {
    return {
      body: {
        reaction: this.innerReaction,
      },
      exerciseUuid: this.exerciseUuid ?? undefined,
      masterGroupId: this.masterGroupId,
      masterId: this.masterId ?? undefined,
      messageId: this.message.id,
      taskUuid: this.taskUuid,
    }
  }

  private sendReaction () {
    MessageModule.sendReaction(this.payload)
      .then(() => {
        this.innerReactionAuthorId = this.user.id
      })
  }

  private handleScroll(value: number) {
    this.reverse = value - this.card.getBoundingClientRect().bottom < 60
  }

  private handleEditMessage() {
    this.$bus.$emit('scrollToBottom')
    this.$bus.$emit('editMessage', { media: this.message.media, message: this.message.message }, this.message.id)
  }
}
