import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import SystemModule from '@/store/modules/system'
import {
  DraftsMessagesFormIdGetRequest, DraftsMessagesFormIdPostRequest,
  IReactionPayload,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesMessageIdReactionPostRequest,
  MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesMessageIdReactionPostRequest,
  StoreMessageDraftRequest,
} from '@/store/types'

/**
* Общие методы для чатов.
* - Отправка реакций на сообщения
* - 😊😉😍🥳😟🥺😳👍❤️🙏😵
*/

export interface FocusMenu {
  colorChoose: boolean,
  imageModal: boolean,
  linkModal: boolean,
  markerChoose: boolean,
  tableChoose: boolean,
  tableModal: boolean,
}

@Module({
  dynamic: true,
  name: 'Editor',
  namespaced: true,
  store,
})
class Message extends VuexModule {
  get isMaster () {
    return SystemModule.interface === 'master'
  }

  @Action({ rawError: true })
  async sendReaction(payload: IReactionPayload) {
    if (this.isMaster && payload.exerciseUuid)
      return await MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesMessageIdReactionPostRequest(
        payload.masterGroupId,
        payload.exerciseUuid,
        payload.taskUuid,
        payload.messageId,
        payload.body,
      )
    else if (payload.masterId)
      return await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesMessageIdReactionPostRequest(
        payload.masterGroupId,
        payload.taskUuid,
        payload.masterId,
        payload.messageId,
        payload.body,
      )
  }

  @Action({ rawError: true })
  async fetchDraftMessage(formID: number) {
    const { data } = await DraftsMessagesFormIdGetRequest(formID)
    return data
  }

  @Action({ rawError: true })
  async saveDraftMessage(payload: { body: StoreMessageDraftRequest, formID: number }) {
    const { data } = await DraftsMessagesFormIdPostRequest(payload.formID, payload.body, { loading: false })
    return data
  }
}

const MessageModule = getModule(Message)

export default MessageModule
